const messages={
    "username.required":"Email is required",
    "username.invalid":"Email is invalid",
    "password.required":"Password is required",
    "password.invalid":"Password is invalid",
    "password.minlength":"Password length minium should be 6 characters",
    "password.pattern":"Password pattern not matched",
    "name.required":"Name is required",
    "sortname.required":"Sort name is required",
    "phonecode.required":"Phone code is required",
    "role_name.required":"Role name is required",
    "bg_name.required":"Biogeographic name is required",
    "email.required":"Email Id is required",
    "email.invalid":"Email is invalid",
    "confirm_password.required":"Confirm password is required",
    "confirm_password.invalid":"Password and Confirm password is not matched",
    "cpassword.required":"Confirm password is required",
    "cpassword.invalid":"Password and Confirm password is not matched",
    "first_name.required":"First name is required",
    "last_name.required":"Last name is required",
    "user_biographic_region.required":"Biographic region is required",
    "user_role.required":"User role is required",
    "user_phone.required":"User phone is required",
    "user_phone.invalid":"User phone is not valid",
    "country_id.required":"Country name is required",
    "user_state_id.required":"State name is required",
    "dataname.required":"Data Name is required",
    "organization_name.required":"Organization Name is required",
    "cat_id.required":"Category is required",
    "about_provider.required":"About provider is required",
    "data_provider_url.required":"Data provider url is required",
    "data_type.required":"Data type is required",
    "data_provider_type.required":"Data provider type is required",
    "user_address.required":"User address is required",
    "emailid.required":"Email Id is required",
    "emailid.invalid":"Email is invalid",
    "contactnumber.required":"Phone is required",
    "contactnumber.invalid":"Phone number is invalid",
    "address2.required": "Address is required",
    "address.required": "Address is required",
    "zipcode.required": "Zipcode is required",
    "state.required": "State is required",
    "agentname.required" : "Agent name is required",

    "agentid.required" : "Agent  is required",
    "crmname.required" : "CRM name is required",
    "environment.required" : "Environment  is required",
    "apikeys.required" : "Apikeys  is required",
    "apiendpoints.required" : "Api-end-points  is required",
    "apitokens.required" : "Api-tokens  is required",
    "otherinformations.required" : "Other information is required",
    "accountid.required" : "Account id is required",
    "baseurl.required":"Base url is required",
    "authtokendpoint.required":" Auth token point is required",
    "clientid.required":"Client id is required",
    "clientsecretkey.required":"Client secret key is required",
    "otherinformation.required":"Other information is required",
    "updateStatusTime.required":"Update status time is required",
    "update_time_status.required":"Update time status is required",
    "schedule_interval.required":"Schedule Interval  is requried",
    "schedule_start_time.required":"Schedule start time is requried",



    "property_address.required" : "Property address  is required",
    "city.required" : "Suburb is required",
    "first_name.required" : "First name  is required",
    "last_name.required" : "Last name  is required",
    "Email_id.required" : "Email id  is required",
    "country_code.required" : "Country code  is required",
    "phone.required" : "Phone number is required",
    "funding_amount.required" : "Funding amount is required",
    "funding_amount.invalid" : "Amount must be greater than or equal to 1.",
    "Email_id.invalid":"Email is invalid",
    "phone.invalid":"Phone is not valid",
    "termconditonCheck.required":"Please check terms and condition ",
    "phone.lengthAus":"Phone is not valid",
    "phone.lengthNew":"Phone is not valid",
}
export default messages;
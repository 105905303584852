import React, { Component } from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const ListGlobleLog = (props) => {
    //console.log("this list page ",props.logHistoryData);
    return (
        <>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#" className="breadcrumb-link">Dashboard</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Global Connector Log</li>
                </ol>
            </nav>
            <div className="card">
                <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <h5 className="hed01">Global Connector Log</h5>
                        {/* {props.logHistoryData.length > 0 && */}
                        <input className="form-control serch_hed"
                            type="searchText"
                            value={props.searchText}
                            onChange={(e) => {
                                props.searchHandleChange(e);
                            }}
                            placeholder="Search.."
                        />
                        {/* } */}
                        {/* <Link to='../admin/addinternalcrm' className="btn btn-info rounded"><i className="fal fa-plus"></i> Add</Link> */}
                    </div>
                    <form autoComplete="off">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className="col-sm-6 col-lg-4">
                            <label>Select Connector</label><br></br>
                            <select className="form-control serch_hed"
                                name="connectorid"
                                value={props.connectorid}
                                onChange={(e) => props.onHandleChangeAdvSerch(e)}
                            >
                                <option value="0">--Select--</option>
                                {props.connectorDropDawn && props.connectorDropDawn.length > 0 && (
                                    props.connectorDropDawn.map((item, index) => (
                                        <option key={index} value={+item.id}>{item.name}</option>
                                    )))}
                            </select>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <label>Select Start Date</label><br></br>
                            {/* <input className="form-control serch_hed"
                         type="date"
                         name='startdate'
                         value={props.startdate}
                        // value={moment(props.startdate).format("dd/mm/yyyy") }
                        onChange={(e) => {
                           props.onHandleChangeAdvSerch(e);
                          }}
                        
                         /> */}

                            <DatePicker
                                name='startDate'
                                selected={props.startdate}
                                onChange={(date) => props.onHandleChangeStartDate(date)}
                                dateFormat="dd/MM/yyyy"
                                placeholderText='DD/MM/YYYY'
                                isClearable={props.startdate ? true : false}
                            />
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <label>Select End Date</label><br></br>
                            {/* <input className="form-control serch_hed"
                         type="date"
                         name='enddate'
                         value={props.enddate}
                        onChange={(e) => {
                           props.onHandleChangeAdvSerch(e);
                          }}
                         
                         /> */}
                            <DatePicker
                                name='enddate'
                                selected={props.enddate}
                                onChange={(date) => props.onHandleChangeEndDate(date)}
                                dateFormat="dd/MM/yyyy"
                                placeholderText='DD/MM/YYYY'
                                isClearable={props.enddate ? true : false}
                            />
                        </div>
                        <div className="col-sm col-lg pt-4">
                            <button onClick={() => props.getHistoryList()} type="button"
                                className="btn btn-outline-primary">Search</button>
                        </div></div></form>
                    <div className="overflow-auto">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">CRM</th>
                                    {/* <th scope="col">CRM</th> */}
                                    <th scope="col">Third Party Id </th>
                                    <th scope="col">Internal Id </th>
                                    <th scope="col">Connector Name</th>
                                    <th scope="col">Action</th>
                                    <th scope="col">Added Date</th>
                                    <th scope="col">View Detail</th>
                                </tr>
                            </thead>
                            <tbody>

                                {props.logHistoryData && props.logHistoryData.length > 0 ? (
                                    props.logHistoryData.map((item, index) => (
                                        <tr key={index}>
                                            <th scope="row">{item.id}</th>
                                            <td>{item.logType}</td>

                                            <td>{item.propertyId}</td>
                                            <td> 
                                                {(item?.logType == "Campaignflow" && item?.responseData)
                                                ? (() => {
                                                    try {
                                                        const parsedData = JSON.parse(item?.responseData);
                                                        return parsedData?.id || "---";
                                                    } catch (error) {
                                                        return "---";
                                                    }
                                                })()
                                                : "---"}</td>
                                            <td>{item.cf_api_connector?.name}</td>
                                            <td>{item.apiType}</td>
                                            <td>{new Date(parseInt(item.addDate)).toDateString()}</td>
                                            <td> <a href="#" onClick={() => props.onEditClick(item.id)}>
                                                <Link to={`/admin/listGlobleLog/logDetail/view/${item.id}`} title="Details" className="btn btn-sm btn-outline-dark">
                                                    <i className="fal fa-book" ></i></Link></a>
                                                {/* <a href='#'
                                             title="Remove" 
                                             onClick={() =>props.openDeleteModal(item.id,1)}
                                             data-toggle="modal"
                                             data-target="#detele-popup"
                                            
                                            ><i className="far fa-trash"></i></a> */}
                                            </td></tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={8} >
                                            <div className="no-data text-center">
                                                {/* <img src="/assets/images/empty-box.svg" /> */}
                                                <h5 className="p-6">No Data Found</h5>
                                            </div>
                                        </td>
                                    </tr>
                                )}





                            </tbody>
                        </table>
                    </div>
                </div>
            </div>



        </>
    );

}
export default ListGlobleLog;